<template>
  <div id="notice" class="sub_wrap">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "News",
}
</script>
